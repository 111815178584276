import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulService } from '../services/contentful.service';
import { EnvironmentVariablesService } from '../services/environment-variables.service';
import { SearchEngineService } from '../services/search-engine.service';
import { ContentfulConfigService } from '../services/contentful-config.service';
import { InvestorPortfolioSeriesReportingDealBreadcrumb } from '../models/classes/investor-portfolio-series-reporting-deal-breadcrumb.class';
import { InvestorPortfolioSeriesReportingHoldingBreadcrumb } from '../models/classes/investor-portfolio-series-reporting-holding-breadcrumb.class';
import { BreadcrumbDataService } from '../services/breadcrumb-data.service';
import { KeyValuePair } from '../models/classes/key-value-pair.class';
import { HighchartsService } from '../services/highcharts-service.service';
import { HoldingDashboard } from '../models/classes/holding-dashboard.class';
import { ApiService } from '../core/http/api.service';
import { Asset } from '../models/classes/asset.class';
import { HoldingDashboardDetails } from '../models/classes/holding-dashboard-details.class';
import { SnapshotService } from '../services/snapshot.service';
import { UserService } from '../services/user.service';
import { LoadingService } from '../services/loading.service';
import { LoggingService } from '../services/logging.service';
import { HoldingMeta } from '../models/classes/holding-meta.class';
import { NgTourService } from '../services/ng-tour-ui.service';
import { NgTourStepsService } from '../services/ng-tour-steps.service';
import { AssetClassPipe } from '../pipes/asset-class.pipe';
import { AssetSummaryContentful } from '../models/classes/asset-summary-contentful.class';
import { TourService } from 'ngx-ui-tour-md-menu';
import { InvestorRepository } from '../services/repositories/investor.repository';
import { TableLogicService } from '../services/table-logic.service';
import { ActivityTrackingService } from '../services/activity-tracking.service';

@Component({
  selector: 'app-holdings-dashboard',
  templateUrl: './holdings-dashboard.component.html',
  styleUrls: ['./holdings-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HoldingsDashboardComponent implements OnInit {
  errorMessage: any;
  hasError: boolean = false;
  holdingSummary: any;
  holdingTitle: any;
  cfmanagerLogo: string;
  iconSet: {};
  managerId: number;
  asOfDropdownData: string[] = [];
  selectedAsOfDate: string = '';
  valReportingDate: string = '';
  queryParamsSeriesId: number;
  showAssetWidgets: boolean = true;
  holdingDateHidingWidgetsObject: Date = new Date(this.evService.holdingDateForHidingWidgets);
  breadcrumbs: any[] = [];
  dealAndHoldingsDataForBreadcrumb: InvestorPortfolioSeriesReportingDealBreadcrumb[] = [];
  queryParamsHoldingId: number;
  queryParamsInvestorId: number;
  dealName: string = '';
  holdingNameForBreadcrumb: string = '';
  params = {};
  seriesName: string = '';
  portfolioName: string = '';
  widgets: Record<string, KeyValuePair<string | number, number>[]> = {};
  error = this.highchartsService.noResultsText;
  dashboardData: HoldingDashboard = new HoldingDashboard();
  assets: Asset[] = [];
  summary: HoldingDashboardDetails = new HoldingDashboardDetails();
  firstTimeLoadingDeals: boolean = true;
  showNoAccessError: boolean = false;
  currency: string = 'USD';
  assetClassImage: Array<string> = [];
  isSingleAssetHolding: boolean = false;
  assetSummary: AssetSummaryContentful = new AssetSummaryContentful();
  dealDetailLabel: string = '';
  assetDetailLabel: string = '';
  isHoldingDataUnavailable: boolean = false;
  isDashboardApiError: boolean = false;
  assetMasterId: number;

  constructor(
    private router: Router,
    private contentfulService: ContentfulService,
    private evService: EnvironmentVariablesService,
    private seoService: SearchEngineService,
    private contentfulConfig: ContentfulConfigService,
    private ref: ChangeDetectorRef,
    public breadcrumbDataService: BreadcrumbDataService,
    private activatedRoute: ActivatedRoute,
    private highchartsService: HighchartsService,
    private apiService: ApiService,
    public snapService: SnapshotService,
    private userService: UserService,
    private loadingService: LoadingService,
    private loggingService: LoggingService,
    private ngTourSteps: NgTourStepsService,
    private ngTourUi: NgTourService,
    private tourService: TourService,
    private assetClassPipe: AssetClassPipe,
    private investorRepository: InvestorRepository,
    private tableLogicService: TableLogicService,
    public activityTracking: ActivityTrackingService
  ) {
    this.ngTourSteps.getContentfulDataFetchedValue().subscribe((data: boolean) => {
      this.tourService.setDefaults({
        ...this.ngTourSteps.defaultOptions,
        isOptional: true
      });
      if (data) this.ngTourUi.setTourSteps(ngTourSteps.holdingDashboardSteps);
    })
  }

  ngOnInit(): void {
    this.showNoAccessError = false;
    this.params = this.userService.params = this.activatedRoute.snapshot.params;
    const queryParams = this.userService.queryParams = this.activatedRoute.snapshot.queryParams;
    this.contentfulService.getIconSetValue().subscribe(data => {
      if (data) {
        this.iconSet = data;
        this.setFilters(queryParams, this.params);
      }
    });
  }

  setFilters(queryParams, params) {
    this.seoService.setCanonicalURL();
    this.managerId = (queryParams.managerId === undefined) ? '' : queryParams.managerId;
    this.queryParamsHoldingId = params['holdingId'] ?? '';
    this.queryParamsInvestorId = params['investorId'] ?? '';
    this.queryParamsSeriesId = params['seriesId'] ?? '';
    this.valReportingDate = queryParams['reportingdate'];
    this.getHoldingsCommonAssets();
    this.checkIfUserIsExternal();
  }

  checkIfUserIsExternal() {
    if (this.userService.isExternalUser()) {
      this.checkExternalUserAccess();
    } else {
      this.getHoldingDashboardDetails();
    }
  }

  getMetaData(holdingId?) {
    // getting reporting periods and breadcrumb data from new API endpoint
    this.apiService.getHoldingDashboardMetadata(this.queryParamsSeriesId, holdingId ?? this.queryParamsHoldingId).subscribe({
      next: (data: HoldingMeta) => {
        this.asOfDropdownData = data.reportingPeriods.filter(period => new Date(period).getTime() >= new Date(this.evService.hideDatesPriorToDate).getTime()).map(period => period.slice(0, 10));
        if (!this.isDashboardApiError) {
          if (this.valReportingDate && this.asOfDropdownData.filter(date => date === this.valReportingDate).length > 0) {
            this.selectedAsOfDate = this.valReportingDate;
          }
          else if (this.asOfDropdownData[0]) {
            this.selectedAsOfDate = this.valReportingDate = this.asOfDropdownData[0];
          }
        }
        if (this.firstTimeLoadingDeals) {
          this.dealAndHoldingsDataForBreadcrumb = this.tableLogicService.sortDataForSeriesTable(data.relatedEntities);
          this.firstTimeLoadingDeals = false;
        }
        if (holdingId) {
          this.getDataForBreadcrumb(holdingId)
        }
        this.getHoldingName();
        this.breadcrumbs = [];
        const dashboardBreadcrumb = this.breadcrumbDataService.getDashboardBreadcrumb(this.queryParamsInvestorId);
        this.breadcrumbs.push(dashboardBreadcrumb);
        this.breadcrumbs = [{ id: this.queryParamsInvestorId, name: 'Dashboard', route: '/dashboard', data: null, type: 'Dashboard' }];
        const seriesBreadcrumbs = this.breadcrumbDataService.getPortfolioSeriesBreadcrumbs(this.queryParamsInvestorId, this.queryParamsSeriesId, data.parent)
        this.breadcrumbs.push(seriesBreadcrumbs);
        this.isHoldingDataUnavailable = this.isDashboardApiError;
        if (this.isDashboardApiError) {
          this.asOfDropdownData.push(this.valReportingDate);
          this.selectedAsOfDate = this.valReportingDate;
        }
        this.loadingService.setLoading(false);
      },
      error: (err) => {
        this.loggingService.log("----- Holding Dashboard Metadata API Exception -----", err);
        this.loadingService.setLoading(false);
      }
    });
  }

  getHoldingsCommonAssets() {
    this.contentfulService.fetchCommonAssets('holdings', '', this.contentfulConfig.holdingsDashboard).subscribe((data: any) => {
      let labelItems = data?.data?.landingPage?.sectionsCollection?.items[0]?.resourcesCollection?.items;
      if (labelItems?.length > 0) {
        labelItems.forEach(item => {
          if (item.key == 'label.deal.detail') {
            this.dealDetailLabel = item.value;
          }
          if (item.key == 'label.asset.detail') {
            this.assetDetailLabel = item.value;
          }
        })
      }
    })
  }

  getHoldingLogo() {
    if (this.managerId == null || this.managerId === 0) {
      this.cfmanagerLogo = '';
      return;
    }
    this.contentfulService.fetchCommonAssets('investmentManager', '', this.managerId.toString()).subscribe((data: any) => {
      if (data.data.investmentManagerCollection.items.length === 0) {
        this.cfmanagerLogo = '';
      }
      else {
        this.cfmanagerLogo = data.data.investmentManagerCollection.items[0].logo.url;
      }
    });
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  updateAsOfDate(date) {
    this.activityTracking.toggledAsOfDate(date)
    this.loadingService.setLoading(true);
    this.fetchDashboardDataFromApi(this.queryParamsSeriesId, this.queryParamsHoldingId, date).then((data: HoldingDashboard) => {
      this.assignDataToAllWidgets(data);
      if(this.isHoldingDataUnavailable){
        this.getMetaData()
      }
      this.valReportingDate = date;
      this.router.navigate(
        [], {
        relativeTo: this.activatedRoute,
        queryParams: { reportingdate: date },
        queryParamsHandling: 'merge'
      });
      this.loadingService.setLoading(false);
    });
  }

  goToSelectedHoldings(holdingId) {
    // check metadata first before going to selected holdings in case selected as of date doesn't have data for holding
    this.getMetaData(holdingId)
  }

  getDataForBreadcrumb(holdingId) {
    let selectedHoldings = new InvestorPortfolioSeriesReportingHoldingBreadcrumb();
    this.loadingService.setLoading(true);
    this.dealAndHoldingsDataForBreadcrumb.map(investmentType => {
      investmentType.subEntities.filter(deal => {
        selectedHoldings = deal.subEntities.filter(entity => entity.id == holdingId)[0];
        if (selectedHoldings) {
          const holding = selectedHoldings
          this.fetchDashboardDataFromApi(this.queryParamsSeriesId, holdingId, this.selectedAsOfDate).then((data: HoldingDashboard) => {
            this.assignDataToAllWidgets(data);
            this.queryParamsHoldingId = holding.id;
            let route = `/investor/${this.params['investorId']}/series/${this.queryParamsSeriesId}/holding/${holding.id}`;
            this.holdingNameForBreadcrumb = deal.subEntities.length === 1 ? deal.name : holding.name;
            this.managerId = data.summary?.investmentManagerMasterId;
            this.getHoldingLogo();
            this.getHoldingsCommonAssets();
            this.router.navigate([route], {
              relativeTo: this.activatedRoute,
              queryParams: { reportingdate: this.selectedAsOfDate },
              queryParamsHandling: 'merge'
            });
            this.loadingService.setLoading(false);
          });
          return;
        }
      })
    });
  }

  getHoldingName() {
    let selectedHoldings = new InvestorPortfolioSeriesReportingHoldingBreadcrumb();
    this.dealAndHoldingsDataForBreadcrumb.map(investmentType => {
      investmentType.subEntities.filter(deal => {
        selectedHoldings = deal.subEntities.filter(entity => entity.id == Number(this.queryParamsHoldingId))[0];
        if (selectedHoldings) {
          if(this.isDashboardApiError){
            this.holdingTitle = selectedHoldings.name;
            this.dealName = deal.name;
          }
          this.holdingNameForBreadcrumb = deal.subEntities.length === 1 ? deal.name : selectedHoldings.name;
          this.getHoldingLogo();
        }
      })
    });
  }

  checkExternalUserAccess() {
    const assignedInvestorIds = this.userService.getInvestorIds();
    if (!assignedInvestorIds.includes(Number(this.queryParamsInvestorId))) {
      this.hasError = true;
      this.showNoAccessError = true;
      this.loadingService.setLoading(false);
      return;
    }
    else {
      this.hasError = false;
      this.getHoldingDashboardDetails();
    }
  }

  getHoldingDashboardDetails() {
    this.loadingService.setLoading(true);
    this.fetchDashboardDataFromApi(this.queryParamsSeriesId, this.queryParamsHoldingId, this.valReportingDate).then((data: HoldingDashboard) => {
      this.assignDataToAllWidgets(data);
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          reportingdate: data?.summary?.reportingPeriod?.slice(0, 10)
        },
        queryParamsHandling: 'merge'
      });
      this.managerId = data.summary?.investmentManagerMasterId;
      this.getHoldingLogo();
      this.getMetaData();
      this.loadingService.setLoading(false);
    });
  }

  fetchDashboardDataFromApi(seriesId, holdingId, reportingDate) {
    return new Promise((resolve) => {
      this.apiService.getHoldingDashboardDetails(seriesId, holdingId, reportingDate).subscribe({
        next: (data) => {
          this.isDashboardApiError = false;
          resolve(data);
        },
        error: (err) => {
          this.loadingService.setLoading(false);
          if (err?.error?.errors?.length > 0) {
            if (err?.error?.errors[0]?.message?.includes('User does not have access')) {
              this.isDashboardApiError = true;
              this.loadingService.setLoading(true);
              this.getMetaData();
            }
          }
          this.queryParamsHoldingId = holdingId;
          let route = `/investor/${this.params['investorId']}/series/${this.queryParamsSeriesId}/holding/${holdingId}`;
          this.router.navigate([route], {
            relativeTo: this.activatedRoute,
            queryParams: { reportingdate: reportingDate },
            queryParamsHandling: 'merge'
          });
          this.loggingService.log("Error while fetching holding data", err);
        }
      });
    })
  }

  assignDataToAllWidgets(data: HoldingDashboard) {
    this.dashboardData = data;
    if (data.assets) {
      this.assets = data.assets;
      this.isSingleAssetHolding = this.assets.length === 1;
      if (this.isSingleAssetHolding) {
        this.assetMasterId = this.assets[0].masterId;
        // We do not want to fetch the asset details for the masked asset
        if (this.assetMasterId != 0) {
          this.fetchCommonAssetsForSingleAssetHolding();
          if (this.assets[0].businessDescription && this.assets[0].businessDescription != '') {
            this.assetSummary.summary = this.assets[0].businessDescription.replace(this.contentfulConfig.summaryRegExp, this.contentfulConfig.summaryValueToReplace);
          }
          if (this.assets[0].website && this.assets[0].website != '') {
            this.assetSummary.showAssetUrl = true;
            let url = this.assets[0].website;
            this.assetSummary.assetURL = !(/(http(s?)):\/\//i.test(url)) ? "https://" + url : url;
          }
          else {
            this.assetSummary.showAssetUrl = false;
          }
        }
      }
    }
    if (data.widgets) {
      this.widgets = data.widgets
    }
    if (data.summary) {
      if (data.summary.dealAssetClass === 'ARS') {
        data.summary.dealAssetClass = 'Absolute Returns Strategy'
      }
      if (data.summary.dealAssetClass) {
        this.assetClassImage = [data.summary.dealAssetClass, this.iconSet[this.assetClassPipe.transform(data.summary.dealAssetClass)]];
      }
      this.summary = data.summary;
      this.currency = this.summary.currency
      this.holdingTitle = this.summary.name;
      this.dealName = this.summary.dealName;
      this.seriesName = this.summary.portfolioSeriesName;
      this.portfolioName = this.summary.portfolioName;
      this.holdingSummary = this.summary.holdingDescription;
    }
  }

  getSnapshot() {
    this.activityTracking.downloadSnapshot(this.queryParamsHoldingId, this.holdingTitle);
    let fileName = 'HoldingsSnapshot_' + this.holdingTitle.replace(/\s/g, "_") + '_' + this.valReportingDate + '.pdf'
    this.snapService.getSnapshot(this.queryParamsSeriesId, this.valReportingDate, fileName, this.queryParamsHoldingId);
  }

  fetchCommonAssetsForSingleAssetHolding() {
    this.contentfulService.fetchCommonAssets('assetSummary', '', this.assets[0].masterId.toString()).subscribe((assetData: any) => {
      if (assetData.data.assetDetailsCollection.items.length === 0) {
        this.assetSummary.title = '';
        this.assetSummary.logo = '';
        this.assetSummary.slidingImgs = [];
      }
      else {
        this.assetSummary.title = assetData.data.assetDetailsCollection.items[0].name;
        if (!assetData.data.assetDetailsCollection.items[0].logo) {
          this.assetSummary.logo = '';
        }
        else {
          this.assetSummary.logo = assetData.data.assetDetailsCollection.items[0].logo.url;
        }

        if (assetData.data.assetDetailsCollection.items[0].photosCollection.items.length !== 0) {
          this.assetSummary.slidingImgs = [];
          for (let ssind = 0; ssind < assetData.data.assetDetailsCollection.items[0].photosCollection.items.length; ssind++) {
            this.assetSummary.slidingImgs[ssind] = { src: assetData.data.assetDetailsCollection.items[0].photosCollection.items[ssind].url };
          }
        } else {
          this.assetSummary.slidingImgs = [];
        }
      }
    });
  }

  breadcrumbClick(breadcrumb) {
    this.activityTracking.navigateToDashboard(breadcrumb.type === 'Dashboard' ? 'Investor' : breadcrumb.type, breadcrumb, 'holding dashboard breadcrumbs');
    if (breadcrumb.route.includes('/dashboard')) {
      this.investorRepository.setActiveEntity(breadcrumb.id);
    }
  }
}

